import logo from "./logo.svg";
import React, { useContext, useEffect, useState } from "react";
import { intervalToDuration } from "date-fns";
import summerimg from "./imgs/summer.jpg";
import vetur from './imgs/vetur.jpg';
import vor from './imgs/vor.jpg';
import haust from './imgs/haust.jpg';


import TimeUntil from "./components/timeuntill";
const GetSpecialDate = (date, weeks, weekday) => {
  var rtnDate = new Date(date);
  var weekd = 7 * weeks;
  var d = (weekday + 7 - rtnDate.getDay()) % 7;
  rtnDate.setDate(rtnDate.getDate() + weekd + d);
  return rtnDate;
};

const GetHeyannir = (d) => {
  // find the date 23.07 curr year
  var hyday = new Date(d + "-07-23");
  if (hyday.getDay() === 0)
    //sunnudagur
    return hyday;
  return GetSpecialDate(hyday, 0, 0);
};
const month = [
  "Jánúar",
  "Febrúar",
  "Mars",
  "Apríl",
  "Maí",
  "Júní",
  "Júlý",
  "Ágúst",
  "September",
  "Október",
  "Nóvember",
  "Desember",
];
const now = new Date().toLocaleTimeString();
function App() {
  const d = new Date();
  const [isSummer, setIsSommer] = useState();
  const [bgImg, setBgImg] = useState(summerimg);
  const [season, setSeason] = useState();
  const [runonce, setrunonce] = useState();
  const [oldMonth, setOldMonth] = useState({});
  const [time, setTime] = useState(now);

  useEffect(() => {
    document.title = "Er sumarið komið?";

    var Sumardagurinn = GetSpecialDate(
      new Date(d.getFullYear() + "-04-19"),
      0,
      4
    );
    var vetrardagur = GetSpecialDate(Sumardagurinn, 26, 6);
    var pastYear = d.getFullYear() - 1;
    var SumardagurinnLY = GetSpecialDate(new Date(pastYear + "-04-19"), 0, 4);
    var vetrardagurLY = GetSpecialDate(SumardagurinnLY, 26, 6);
    /**Sumardagurinn fyrsti því lent á 19. til 25. apríl en hann er þó alltaf fyrsta fimmtudag eftir 18. apríl. */

    const dates = [
      { type: "þorri", date: GetSpecialDate(vetrardagurLY, 13, 5) }, //þorri hefst föstudag í 13. viku vetrar (19. – 26. janúar)
      { type: "góa", date: GetSpecialDate(vetrardagurLY, 18, 7) }, //góa hefst sunnudag í 18. viku vetrar (18. – 25. febrúar)
      { type: "einmánuður", date: GetSpecialDate(vetrardagurLY, 22, 2) }, //einmánuður hefst þriðjudag í 22. viku vetrar (20. – 26. mars)
      { type: "harpa", date: GetSpecialDate(Sumardagurinn, 0, 4) }, //harpa hefst sumardaginn fyrsta, fimmtudag í 1. viku sumars (19. – 25. apríl)
      { type: "skerpla", date: GetSpecialDate(Sumardagurinn, 4, 6) }, //skerpla hefst laugardag í 5. viku sumars (19. – 25. maí)
      { type: "sólmánuður", date: GetSpecialDate(Sumardagurinn, 8, 1) }, //sólmánuður hefst mánudag í 9. viku sumars (18. – 24. júní)
      { type: "heyannir", date: GetHeyannir(d.getFullYear()) }, //heyannir hefjast á sunnudegi 23. – 30. júlí
      { type: "tvímánuður", date: GetSpecialDate(Sumardagurinn, 17, 2) }, //tvímánuður hefst þriðjudag í 18. viku sumars (22. – 29. ágúst)
      { type: "haustmánuður", date: GetSpecialDate(Sumardagurinn, 22, 4) }, //haustmánuður hefst fimmtudag í 23. viku sumars (20. – 26. september)
      { type: "gormánuður", date: GetSpecialDate(Sumardagurinn, 26, 6) }, //gormánuður hefst fyrsta vetrardag, laugardag í 1. viku vetrar (21. – 28. október)
      { type: "ýlir", date: GetSpecialDate(vetrardagur, 4, 1) }, //ýlir hefst mánudag í 5. viku vetrar (20. – 27. nóvember)
      { type: "mörsugur", date: GetSpecialDate(vetrardagur, 8, 3) },
    ]; //mörsugur hefst miðvikudag í 9. viku vetrar (20. – 27. desember)

    //https://www.visindavefur.is/svar.php?id=1132
    var curr = { type: "mörsugur", date: GetSpecialDate(vetrardagur, 8, 3) };
    dates.forEach((element) => {
      console.log(element)
      if (d > element.date) {
        curr = element;
        return;
      }
    });
    
    setOldMonth(curr);

    //Haust: Á Veðurstofunni er haustið talið 2 mánaða langt, frá 1. október til 30. nóvember. Þetta er styttra tímbabil en miðað er við erlendis því þar er september einnig talinn til haustmánaða. (Sjá einnig, vetur, vor, sumar.)
    // 9-10

    //Sumar: Í samanburðarreikningum á Veðurstofunni er sumarið talið 4 mánaða langt, frá 1. júní til 30. september. Þetta er lengra tímabil en að jafnaði er notað erlendis því þar er miðað við að sumarið standi yfir tímabilið frá júní og út ágúst. Þessi skilgreining Veðurstofunnar kann að orka tvímælis en varð ofan á á sínum tíma til þess að skýrslusumarið væri jafnlangt og veturinn. September hefur í raun sennilega oftar veðureinkenni hausts fremur en sumars, þó það sé raunar misjafnt frá ári til árs. (Sjá einnig, vetur, vor, haust.)
    // 5-8
    //Vor: Á Veðurstofunni er vorið talið 2 mánaða langt, frá 1. apríl til 31. maí. Þetta er styttra tímabil en miðað er við erlendis því þar er mars einnig talinn til vormánaða. (Sjá einnig, vetur, sumar, haust.)
    // 3-4
    //Vetur: Í samanburðarreikningum á Veðurstofunni er vetur talinn 4 mánaða langur, frá 1. desember til 31. mars. Þetta er lengra tímabil en að jafnaði er notað erlendis því þar er miðað við að veturinn standi yfir tímabilið frá desember og út febrúar. Hér á landi er mars alloft kaldasti mánuður ársins og því er erfitt að tala um hann sem vormánuð. (Sjá einnig, vor, sumar, haust.)
    //0-3
    //12
    //current month

    switch (d.getMonth()) {
      case 0:
      case 1:
      case 2:
      case 11:
        setSeason("vetur");
        setBgImg(vetur);
        break;
      case 5:
      case 6:
      case 7:
      case 8:
        setSeason("sumar");
        setIsSommer(true);
        setBgImg(summerimg);
        break;
      case 3:
      case 4:
        setSeason("vor");
        setBgImg(vor);
        break;
      case 9:
      case 10:
        setSeason("haust");
        setBgImg(haust);
        break;
      default:
        setSeason("");
    }
  }, [runonce]);

  return (
    <div className={`min-h-screen bg-cover bg-center flex flex-col items-center justify-center h-screen`}
    style={{backgroundImage: `url(${bgImg})`}}>
      <div className="bg-amber-800 bg-opacity-60 text-white p-8 rounded-lg">
        <div className=" uppercase text-3xl w-full text-center">Er sumar ?</div>
        {isSummer ? <div>jábbs</div> : <div>Nei það er {season}</div>}
        <br />
        {oldMonth.date && (
          <div>
            Til fróðleiks er skv gamla tímatalinu {oldMonth.type} sem hófst þann{" "}
            {oldMonth.date.getDate()}. {month[oldMonth.date.getMonth()]}{" "}
          </div>
        )}
        <br></br>
      </div>
      <div className="bg-white bg-opacity-45 text-4xl uppercase border rounded-xl m-4 p-4">Hvað er á næstunni?</div>
      <div className="flex gap-3 flex-wrap pt-6">
        
        <TimeUntil time="2025-04-20T00:00:00" title="Páskar"></TimeUntil>
        <TimeUntil time="2025-06-01T18:00:00" title="sumar"></TimeUntil>
      </div>
    </div>
  );
}

export default App;
